import React from 'react';

const Contact = () => (
  <main>
    <h1>Contact Us</h1>
    <p>Feel free to reach out to us for any inquiries or feedback:</p>
    <ul>
      <li>Email: contact@techbuzzupdates.com</li>
      <li>Phone: +123 456 7890</li>
    </ul>
  </main>
);

export default Contact;
