import ReactGA from 'react-ga';

const trackingId = "G-WXD1VWX9QT"; // Replace with your Google Analytics ID
ReactGA.initialize(trackingId);

const logPageView = () => {
  ReactGA.set({ page: window.location.pathname });
  ReactGA.pageview(window.location.pathname);
};

export { logPageView };
