import React from 'react';

const About = () => (
  <main>
    <h1>About TechBuzz Updates</h1>
    <p>This is an IT-focused blog covering recent technology changes, new gadgets, sci-fi movies related to tech, and more.</p>
  </main>
);

export default About;
