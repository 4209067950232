import React from 'react';

const Home = () => (
  <main>
    <h1>Welcome to TechBuzz Updates</h1>
    <section>
      <h2>Featured Blogs</h2>
      {/* Placeholder for featured blog list */}
      <div>
        <p>Check out our latest blog posts on technology, gadgets, and more!</p>
        {/* Add your blog cards or links here */}
      </div>
    </section>
  </main>
);

export default Home;
